<!-- routify:options title='Home' -->
<!-- routify:options index=1 -->
<!-- routify:options showInNavigation=true -->

<script>
    import { t, storeHashesAreValid } from 'totalinvoice-frontend-core'

    const showClearStorageNotification = !storeHashesAreValid()
</script>

<style>
    .header-margin {
        margin-top: -3em;
    }
    .hero-body {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }</style>

<section class="hero" class:header-margin={!showClearStorageNotification}>
    <div class="hero-body">
        <figure class="image">
            <img src="/images/header_20211014.jpeg" alt="Header">
        </figure>
    </div>
</section>

<h1 class="title">{$t('TotalEnergies Invoicing Admin Platform')}</h1>
