<!-- routify:options title='All our sites' -->
<!-- routify:options index=6 -->
<!-- routify:options showInFooter=true -->

<script>
    import { t } from 'totalinvoice-frontend-core'
</script>

<style>
    h4.subtitle {
        text-transform: none;
    }
    a {
        text-decoration: underline;
    }</style>

<h2 class="title is-2 is-spaced page-title">{$t('All our sites')}</h2>

<h3 class="subtitle is-3">{$t('Site web corporate du Groupe TotalEnergies')}</h3>
<ul class="link-list">
    <li><a href="TotalEnergies.com" target="_blank">{$t('https://totalenergies.com/')}</a></li>
</ul>
<h4 class="subtitle is-4 mt-6">{$t('Sites web français')}</h4>
<ul class="link-list">
    <li><a href="https://www.totalenergies.fr/" target="_blank">{$t('TotalEnergies France')}</a></li>
    <li><a href="http://www.stationelf.fr/" target="_blank">{$t('ELF-stations France')}</a></li>
    <li><a href="http://www.club-agriculture.com/" target="_blank">{$t('TotalEnergies and agriculture')}</a></li>
</ul>

<h3 class="subtitle is-3 mt-6">{$t('Sites web par pays:')}</h3>
<h4 class="subtitle is-4 mt-6">{$t('Europe')}</h4>
<ul class="link-list">
    <li><a href="https://www.totalenergies.at/" target="_blank">{$t('TOTALENERGIES & ELF in Austria')}</a></li>
    <li><a href="https://corporate.totalenergies.de/" target="_blank">{$t('TOTALENERGIES Germany')}</a></li>
    <li><a href="https://www.totalenergies.be/" target="_blank">{$t('TOTALENERGIES Belgium')}</a></li>
    <li><a href="https://corporate.totalenergies.dk/" target="_blank">{$t('TOTALENERGIES Denmark')}</a></li>
    <li><a href="https://www.totalenergies.es/" target="_blank">{$t('TOTALENERGIES ELF Spain')}</a></li>
    <li><a href="https://services.totalenergies.gr/" target="_blank">{$t('TOTALENERGIES Greece')}</a></li>
    <li>
        <a href="https://www.totalenergies.hu/" target="_blank">{$t('TOTALENERGIES Hungary')}</a>
        <span> / </span>
        <a href="http://www.elf.co.hu/" target="_blank">{$t('ELF Hongria')}</a>
    </li>
    <li><a href="https://services.totalenergies.lu/" target="_blank">{$t('TOTALENERGIES Luxembourg')}</a></li>
    <li><a href="https://www.nordic.totalenergies.com/nb" target="_blank">{$t('TOTALENERGIES Norway')}</a></li>
    <li><a href="https://services.totalenergies.nl/" target="_blank">{$t('TOTALENERGIES Netherlands')}</a></li>
    <li>
        <a href="https://www.totalenergies.pl/" target="_blank">{$t('TOTALENERGIES Poland')}</a>
        <span> / </span>
        <a href="http://www.elf.com.pl/" target="_blank">{$t('ELF Poland')}</a>
    </li>
    <li><a href="https://www.totalenergies.pt/" target="_blank">{$t('TOTALENERGIES Portugal')}</a></li>
    <li>
        <a href="https://www.totalenergies.ro/" target="_blank">{$t('TOTALENERGIES Romania')}</a>
        <span> / </span>
        <a href="http://www.elf.com.ro/" target="_blank">{$t('ELF Romania')}</a>
    </li>
    <li><a href="https://www.totalenergies.cz/" target="_blank">{$t('TOTALENERGIES Czech Republic')}</a></li>
    <li>
        <a href="https://services.totalenergies.ru/" target="_blank">{$t('TOTALENERGIES Russia')}</a>
        <span> / </span>
        <a href="http://www.elf-lub.ru/" target="_blank">{$t('ELF Russia')}</a>
    </li>
    <li><a href="https://www.totalenergies.sk/" target="_blank">{$t('TOTALENERGIES Slovakia')}</a></li>
    <li><a href="https://totalenergies.ch/" target="_blank">{$t('TOTALENERGIES ELF Switzerland')}</a></li>
    <li><a href="https://tr.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Turkey')}</a></li>
</ul>
<h4 class="subtitle is-4 mt-6">{$t('Afrique')}</h4>
<ul class="link-list">
    <li><a href="https://www.totalenergies.co.za/" target="_blank">{$t('TOTALENERGIES South Africa')}</a></li>
    <li><a href="https://bf.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Burkina-Faso')}</a></li>
    <li><a href="https://totalenergies.ke/" target="_blank">{$t('TOTALENERGIES Kenya')}</a></li>
    <li><a href="https://totalenergies.ma/" target="_blank">{$t('TOTALENERGIES Morocco')}</a></li>
    <li><a href="https://services.totalenergies.mu/" target="_blank">{$t('TOTALENERGIES Mauritius')}</a></li>
    <li><a href="http://www.total.com.ng/" target="_blank">{$t('TOTALENERGIES Nigeria')}</a></li>
    <li><a href="https://services.totalenergies.re/" target="_blank">{$t('TOTALENERGIES à la Réunion')}</a></li>
    <li><a href="https://www.total.sn/" target="_blank">{$t('TOTALENERGIES Sénégal')}</a></li>
    <li><a href="https://services.totalenergies.tn/" target="_blank">{$t('TOTALENERGIES Tunisia')}</a></li>
</ul>
<h4 class="subtitle is-4 mt-6">{$t('Amériques')}</h4>
<ul class="link-list">
    <li>
        <a href="https://www.totalenergies.com.ar/" target="_blank">{$t('TOTALENERGIES Argentina')}</a>
        <span> / </span>
        <a href="http://www.elf-lubricantes.com.ar/" target="_blank">{$t('ELF Argentina')}</a>
    </li>
    <li>
        <a href="https://www.totalenergies.com.br/" target="_blank">{$t('TOTALENERGIES Brazil')}</a>
        <span> / </span>
        <a href="http://www.elfbras.com.br/" target="_blank">{$t('ELF Brazil')}</a>
    </li>
    <li><a href="https://totalenergies.mq/" target="_blank">{$t('TOTALENERGIES Caribbean')}</a></li>
    <li><a href="https://www.totalenergies.cl/" target="_blank">{$t('TOTALENERGIES Chile')}</a></li>
    <li><a href="https://www.totalenergies.gp/" target="_blank">{$t('TOTALENERGIES Guadeloupe')}</a></li>
    <li><a href="https://www.totalenergies.mx/" target="_blank">{$t('TOTALENERGIES Mexico')}</a></li>
    <li>
        <a href="https://corporate.totalenergies.us/" target="_blank">{$t('TOTALENERGIES USA')}</a>
        <span> / </span>
        <a href="http://www.elfoils.us/" target="_blank">{$t('ELF USA')}</a>
    </li>
    <li><a href="https://www.jm.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Jamaica')}</a></li>
</ul>
<h4 class="subtitle is-4 mt-6">{$t('Asie')}</h4>
<ul class="link-list">
    <li>
        <a href="https://lubricants.totalenergies.cn/" target="_blank">{$t('TOTALENERGIES China')}</a>
        <span> / </span>
        <a href="http://www.elf.com.cn/" target="_blank">{$t('ELF China')}</a>
    </li>
    <li>
        <a href="https://www.totalenergies.in/" target="_blank">{$t('TOTALENERGIES India')}</a>
        <span> / </span>
        <a href="http://www.elf.co.in/" target="_blank">{$t('ELF India')}</a>
    </li>
    <li>
        <a href="https://www.totalenergies.jp/" target="_blank">{$t('TOTALENERGIES Japan')}</a>
        <span> / </span>
        <a href="http://www.elf-lub.jp/" target="_blank">{$t('ELF Japan')}</a>
    </li>
    <li>
        <a href="https://www.totalenergies.sg/" target="_blank">{$t('TOTALENERGIES Singapore')}</a>
        <span> / </span>
        <a href="http://www.elf.sg/" target="_blank">{$t('ELF à Singapore')}</a>
    </li>
    <li><a href="https://services.th.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Thailand')}</a></li>
    <li><a href="https://kh.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Cambodia')}</a></li>
    <li><a href="https://www.totalenergies.com.fj/" target="_blank">{$t('TOTALENERGIES Fidji')}</a></li>
    <li><a href="https://www.totalparco.com.pk/" target="_blank">{$t('TOTALENERGIES Pakistan')}</a></li>
</ul>
<h4 class="subtitle is-4 mt-6">{$t('Middle East')}</h4>
<ul class="link-list">
    <li><a href="https://www.totalenergies.ae/" target="_blank">{$t('TOTALENERGIES UAE  & ELF UAE')}</a></li>
    <li><a href="https://www.lb.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Liban')}</a></li>
    <li><a href="https://corporate.sa.totalenergies.com/en" target="_blank">{$t('TOTALENERGIES Saudi Arabia')}</a></li>
</ul>

<h3 class="subtitle is-3 mt-6">{$t('Sites web par business unit:')}</h3>
<ul class="link-list">
    <li><a href="http://www.as24.com/" target="_blank">{$t('AS24 european network')}</a></li>
    <li><a href="https://bitumen.totalenergies.com/" target="_blank">{$t('Bitumen TOTALENERGIES in Europe')}</a></li>
    <li><a href="https://lubricants.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Lubricants')}</a></li>
    <li><a href="http://www.lubricants.elf.com/" target="_blank">{$t('ELF Lubricants')}</a></li>
    <li><a href="http://www.lubmarine.com/" target="_blank">{$t('LUBMARINE')}</a></li>
    <li><a href="https://specialfluids.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Special Fluids')}</a></li>
    <li><a href="http://www.elfmoto.com/" target="_blank">{$t('ELF Moto')}</a></li>
    <li><a href="https://acs.totalenergies.com/" target="_blank">{$t('TOTALENERGIES Additives Special Fuels')}</a></li>
    <li><a href="https://lubricants.totalenergies.com/business/oil-diagnosis-anac" target="_blank">{$t('TOTALENERGIES ANAC Diagnosis')}</a></li>
    <li><a href="http://www.stela.fr/" target="_blank">{$t('Stela Online')}</a></li>
    <li><a href="http://www.egedis.com/" target="_blank">{$t('Egedis Carburants')}</a></li>
</ul>
