<script>
    import { DelayedSpinner } from 'totalinvoice-frontend-core'
</script>

<style>
    .wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }</style>

<div class="wrapper">
    <DelayedSpinner />
</div>
