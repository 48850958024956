<!-- routify:options title='Sitemap' -->
<!-- routify:options index=3 -->
<!-- routify:options showInFooter=false -->

<script>
    import { t } from 'totalinvoice-frontend-core'
    import { url } from '@sveltech/routify'
</script>

<style>
    h3 a {
        color: #666;
    }</style>

<h2 class="title is-2 is-spaced page-title">{$t('Sitemap')}</h2>

<h3 class="subtitle is-3"><a href={$url('../terms-of-service')}>{$t('Terms of service')}</a></h3>
<h3 class="subtitle is-3"><a href={$url('../personal-data')}>{$t('Personal data')}</a></h3>
<h3 class="subtitle is-3"><a href={$url('../sitemap')}>{$t('Sitemap')}</a></h3>
<h3 class="subtitle is-3"><a href={$url('../cookies')}>{$t('Cookies')}</a></h3>
<h3 class="subtitle is-3"><a href={$url('../conditions-of-sale')}>{$t('General conditions of sale')}</a></h3>
<h3 class="subtitle is-3"><a href={$url('../all-our-sites')}>{$t('All our sites')}</a></h3>
