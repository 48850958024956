<script>
    import { url } from '@sveltech/routify'
    import { t } from 'totalinvoice-frontend-core'
</script>

<style>
    .huge {
        font-size: 12rem;
    }
    .e404 {
        text-align: center;
    }</style>

<div class="e404">
    <div class="huge">404</div>
    <div class="big">
        {$t('Page not found.')}
        <a href={$url('/')}>{$t('Go back')}</a>
    </div>
</div>
